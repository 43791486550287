import { Album, BatteryFull, Campaign, DeviceThermostat, Pause, PinDrop, PlayArrow } from '@mui/icons-material'
import { LinearProgress } from '@mui/material';
import React from 'react'
import deviceClientServices from '../deviceClientServices';

function DeviceControl({deviceState,trackList, setDeviceState, publishMessage,deviceId}) {

    const handleValueChange = (e) =>{
        let newState = {...deviceState}
        newState[e.target.name]= e.target.value;
        setDeviceState(newState);
    }

    const handleButtonValueChange = (key)=>{
        let newState = {...deviceState}

        //if( newState[key] == 0 ){newState[key] = 1}
        //if( newState[key] == 1 ){newState[key] = 0}
        newState[key] = !newState[key];

        console.log("prepaired new state",newState);
        //setDeviceState(newState);

        
        //console.log("-------------------->");
        //console.log("device id",deviceId);
        let payload = {...newState}
        payload.deviceId = deviceId;
        //console.log("update this log in db");
        //console.log(payload)
        deviceClientServices.updateLog(payload).then(res=>{
            //console.log(res);
        }).catch(error=>{
            //console.log(error);
        })
        //console.log("<--------------------")
        publishMessage(newState);
        
    }

    return (
        <>  
            {/*JSON.stringify(trackList)*/}

            <div className="row">

                <div className="col-md-2 mb-3">
                    <div className="text-danger"><PinDrop/> <span className="fw-bold">{deviceState.Site_ID}</span></div>
                    <div className="text-success"><DeviceThermostat/> <span className="fw-bold">{deviceState.TEMP} &deg;</span></div>
                    <div className="text-primary"><BatteryFull/> <span className="fw-bold">{deviceState.BAT} V</span></div>
                </div>

                <div className="col-md-2 mb-3">
                    <div className="form-floating">
                        <select className="form-select" value={deviceState.T}
                            name="T"
                            onChange={handleValueChange}

                        >
                            {trackList.map(track=>
                                <option key={track.id} value={track.id}>{track.trackTitle}</option>
                            )}
                        </select>
                        <label htmlFor="floatingSelect">Track 1</label>
                    </div>

                </div>

                <div className="col-md-2">
                    <div className="form-floating mb-3">
                        <input type="number" className="form-control" value={deviceState.T_PT} name="T_PT"
                            onChange={handleValueChange}
                        />
                        <label htmlFor="floatingInput">Track Playtime</label>
                    </div>
                </div>
                    <div className="col-md-2">
                        <div className="form-floating mb-3">
                        <input type="number" className="form-control" value={deviceState.H_PT} name="H_PT"
                            onChange={handleValueChange}
                        />
                        <label htmlFor="floatingInput">Hooter Playtime</label>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-floating mb-3">
                        <input type="number" className="form-control" value={deviceState.A_PT} name="A_PT"
                            onChange={handleValueChange}
                        />
                        <label htmlFor="floatingInput">All Playtime</label>
                    </div>
                </div>
                <div className="col-md-1 text-center pb-2">
                    <button type="button" className={deviceState.TS ? "btn btn-primary btn-warning mb-2 btn-lg" : "btn btn-primary btn-success btn-lg mb-2"}
                        onClick={()=>handleButtonValueChange("TS")}
                    > 
                        <Album />{deviceState.TS ? <Pause/> : <PlayArrow/>}
                    </button>
                    {deviceState.TS == 1 && <LinearProgress color="secondary" />}
                </div>
                <div className="col-md-1 text-center pb-2">
                    <button type="button" className={deviceState.HS ? "btn btn-primary btn-warning mb-2 btn-lg" : "btn btn-primary btn-success btn-lg mb-2"}
                         onClick={()=>handleButtonValueChange("HS")}
                    > 
                        <Campaign/> {deviceState.HS ? <Pause/> : <PlayArrow/> }
                    </button>
                    {deviceState.HS == 1 && <LinearProgress color="secondary" />}
                </div>
                

            </div>


            {/* <LinearProgress  style={{color:"#F00",height: 10, borderRadius: 5, backgroundColor:"#0F0"}} /> */}

            <h6>{ /* JSON.stringify(deviceState) */}</h6>
        </>
    )
}

export default DeviceControl