import api from "../../../api/api";


const getList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/client/device").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getTrackList = () => {
    return new Promise((resolve, reject)=>{
        api.get("/admin/track").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateLog = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/client/device/log",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const mqttOptions = {
    // Clean session
    clean: true,
    protocol: "mqtts",//process.env.REACT_APP_MQ_PROTOCOL,
    port: 443,//process.env.REACT_APP_MQ_PORT,
    connectTimeout: 4000,
    // Authentication
    clientId: Math.floor(Math.random() * 100),
    username: process.env.REACT_APP_MQ_USERNAME,
    password: process.env.REACT_APP_MQ_PASSWORD,
}


const mqURL = process.env.REACT_APP_MQ_URL;



const deviceClientServices = {
    getList,
    getTrackList,
    updateLog,
    mqttOptions,
    mqURL
}
export default deviceClientServices;