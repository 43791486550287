import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DeviceControl from './DeviceControl';

function DeviceMQTT({AppAlert,deviceDetails,mqClient,trackList}) {

    const [topic,setTopic] = useState("PA_SYSTEM/"+deviceDetails.deviceName+"/");

    const [deviceState,setDeviceState]  = useState(null);

    useEffect(()=>{
        mqClient.on('connect', () => {
            console.log('Connected for '+deviceDetails.deviceName);
            mqClient.subscribe(topic+"ack", function (err) {
                if (!err) {
                    console.log("subscribed to topic : ", topic+"ack")
                  // Publish a message to a topic
                    let message = {
                        "Device_ID":deviceDetails.deviceName,
                        "State":1
                    }
                    mqClient.publish(topic+'State',JSON.stringify(message))
                } else {
                    console.log("error on subscribe",err)
                }
            })
        });
        return () =>{
            mqClient.unsubscribe(topic+"ack", function (error) {
                if (error) {
                  console.log(error)
                } else {
                  console.log('Unsubscribed ',topic+'ack')
                }
            })
        }
    },[mqClient])

    const publishMessage = (message) =>{
        console.log("publishing message");
        //mqClient.publish(topic+"command",'{"Site_ID":"Area1","Device_ID":"STPAQTT11230001","T":"1205.mp3","T_PT":0,"H_PT":0,"TS":true,"HS":false}');
        delete message.TEMP;
        console.log("device Message",message)
        mqClient.publish(topic+"command",JSON.stringify(message));
        console.log("message published");
    }

    const publishTestMessage = () =>{
        console.log("publishing message");
        mqClient.publish(topic+"command",'{"Site_ID":"Area1","Device_ID":"STPAQTT11230001","T":"1205.mp3","T_PT":0,"H_PT":0,"TS":true,"HS":false}');
        console.log("message published");
    }

    mqClient.on('message', function (topic, message) {
        // message is Buffer
        console.log("message received for "+topic);
        let m = message.toString();
        let messageObj = JSON.parse(m);
        //console.log(messageObj)
        if(messageObj.Device_ID === deviceDetails.deviceName){
            setDeviceState(messageObj);
        }
        //client.end()
    })

    console.log("i am rerendered for "+deviceDetails.deviceName);

    return (
        <div className="card mb-2">
            
                
                
                        
                        <div className="card-header bg-primary text-white">
                            <span className="fw-bold">{deviceDetails.deviceTitle} ({deviceDetails.deviceName})</span>
                        </div>
                        
                        <div className="card-body">
                            {deviceState === null && 
                                <div className="text-center">
                                    <h6><CircularProgress/></h6>
                                    <h6>Getting Device State</h6>
                                </div>
                            }
                            {deviceState !== null && 
                                <DeviceControl 
                                    deviceState={deviceState}
                                    setDeviceState={setDeviceState}
                                    trackList={trackList}
                                    publishMessage={publishMessage}
                                    deviceId = {deviceDetails.id}
                                />
                            }
                        </div>
                    
                
                
        </div>

    )
}

export default DeviceMQTT