import { AccountCircle, CalendarMonthOutlined, DocumentScanner, Fingerprint, Groups, Home,  Label,  ManageAccounts, ManageHistory, MoreVert, Notifications, People, PersonAdd, Settings, TransferWithinAStation, WorkOutlined} from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom'


export default function Header() {
    
    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;

    const navigate = useNavigate(); 
    

    useEffect(()=>{
        if(authUser.verified === 0){
          navigate("/auth/profile");
        }
    },[authUser])


    return (
        <>  { userId && 
            <nav className="navbar shadow navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid m-2">
                    <Link className="navbar-brand" to="/">
                        <span style={{color:"#1377a6", fontSize:"20px"}}>SenseTechno Solutions </span>
                    </Link>
                    <div style={{width:"100%"}} className="text-center"  >
                        <h4 className="fw-bold">
                            {(authUser.profileImage !== null && authUser.profileImage !== "") ?
                                <span>
                                <img src={authUser.profileImage} style={{height:40, width:"auto"}} className="me-2" />
                                {authUser.firstName}
                                </span>
                                :
                                <>{authUser.firstName}</>
                            }
                            
                        </h4>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{fontSize:"14px"}}>
                                { authUser.role ==1 && <>

                                    <li className="nav-item dropdown">
                                        <a className="nav-link active dropdown-toggle" href="/employe-master/list" id="deviceDropDown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Device</a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="deviceDropDown">
                                            <li><NavLink className="dropdown-item" to="/admin/device/list"> <ManageHistory/> Manage Device</NavLink></li>
                                            { /* <li><NavLink className="dropdown-item" to="/admin/site/list"> <ManageHistory/> Manage Sites</NavLink></li> */ }
                                            <li><NavLink className="dropdown-item" to="/admin/track/list"> <ManageHistory/> Manage track</NavLink></li>
                                        </ul>
                                    </li>

                                    {/*
                                    <li className="nav-item">
                                        <NavLink className="nav-link" aria-current="page" to="/"> <Fingerprint/>Attendance</NavLink>
                                    </li>
                                    */}


                                </>}
                        </ul>
                        
                        <ul className="navbar-nav">
                            { authUser.role == 1 && 
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="!#" id="adminControllDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVert/> </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="adminControllDropdown">
                                    {/* <li><NavLink className="dropdown-item" to="/system-master/list"><Label /> Status Master</NavLink></li> */}
                                    <li><NavLink className="dropdown-item" to="/business/details"><WorkOutlined/> Business Details</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/user/list"><ManageAccounts/> Manage User Accounts</NavLink></li>
                                    {/*<li><NavLink className="dropdown-item" to="/user/role"><Settings/> Manage User Role</NavLink></li>*/}
                                </ul>
                            </li>
                            }

                            

                            <li className="nav-item dropdown">
                                <a className="btn btn-danger" style={{borderRadius:"50px"}} href="!#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                                    {authUser.firstName.charAt(0)}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                                    <li><NavLink className="dropdown-item" aria-current="page" to="/device/report"> Device Log</NavLink>
                                    </li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        }
        </>
    )
}
