import { Settings } from '@mui/icons-material';
import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import withAlert from '../../../../hoc/withAlert';

import AppLayout from '../../../../layout/components/AppLayout';
import deviceAdminServices from '../../../admin/deviceAdminServices';
import DeviceMQTT from '../components/DeviceMQTT';

import deviceClientServices from '../deviceClientServices';

import mqtt from "precompiled-mqtt";

function DeviceClientList({AppAlert}) {

    const [deviceList,setDeviceList]      = useState(null);
    const [trackList,setTrackList]      = useState(null);
    const [loading,setLoadingStatus]        = useState(false);
    const [mqClient,setMQClient] = useState(null);

    const getDeviceList = () => {
        setLoadingStatus(true);
        deviceClientServices.getList().then(res=>{
            setDeviceList(res.data);
        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    const getTrackList = () => {
        setLoadingStatus(true);
        deviceClientServices.getTrackList().then(res=>{
            setTrackList(res.data);
        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getDeviceList();
        getTrackList();
    },[])



    const connectMQTT = () =>{
        if(deviceList){
            //console.log("device list ready now get mqttOptions",deviceClientServices.mqttOptions)
            //console.log("device list ready now get url",deviceClientServices.mqURL)
            let client  = mqtt.connect(deviceClientServices.mqURL, deviceClientServices.mqttOptions);
            setMQClient(client);
            console.log("mqClientReady");
        }
    }

    useEffect(()=>{
        connectMQTT()
    },[deviceList])

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        { mqClient && trackList && deviceList && deviceList.length > 0 &&
                            <>
                                    {deviceList.map(deviceDetails=>
                                        <DeviceMQTT key={deviceDetails.id} 
                                            deviceDetails={deviceDetails} 
                                            trackList={trackList}
                                            mqClient={mqClient} 

                                        />
                                    )}
                            </>
                        }
                        {!deviceList || deviceList.length == 0 &&
                            <h4 >No device declared click on "Add New" button to declare new device</h4>
                        }
                    </div>
                </div>
            </div>
        
        </AppLayout>
        
    )
}

export default withAlert(DeviceClientList)